import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import './css/appCopy.scss'
import roundedIcon from './assets/rounded-icon.png';
import star from './assets/star.png';
import faqIcon from './assets/faq-icon.png';
import brainIcon from './assets/icon-team-brain.png';
import read from './assets/read.png';
import andrei from './assets/andrei.png';
import doman from './assets/andrei_doman.png';
import vali from './assets/vali.png';
import deni from './assets/deni.png';
import element_1 from './assets/element_1.png';
import cristian from './assets/cristian.png';
import boat from './assets/boat.png';
import discord from './assets/discord.png';
import twitter from './assets/twitter.png';
import logo from './assets/logo.png';
import monalisa from './assets/banner/monalisa.png';
import oldman from './assets/banner/old_man.jpg';
import NFT from './assets/NFT.jpg';
import stripe from './assets/banner/stripe.png';
import portavoce from './assets/roadmap/portavoce.png';
import prize from './assets/roadmap/prize.png';
import monede from './assets/roadmap/monede.png';
import certificat from './assets/roadmap/certificat.png';
import mystery from './assets/roadmap/mystery.png';
import maini from './assets/roadmap/maini.png';
import {Gallery} from "./components";
import gsap from 'gsap';
// import Swiper from 'swiper';
import 'swiper/swiper.scss';

function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`Click buy to mint your NFT.`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `WOW, the ${CONFIG.NFT_NAME} is yours! go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 1;
    if (newMintAmount > 10) {
      newMintAmount = 10;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
    gsap.registerPlugin(ScrollTrigger);

    ScrollTrigger.defaults({
      toggleActions: "restart pause resume pause"
    });

    const roadmapIds = ['Vinci', 'First-Florentine-period', 'First-Milanese-period', 'Second-Florentine-period', 'Second-Milanese-period', 'Rome', 'France'];
    const imgMargins = [-30, 70, -40, 70, -40];
    roadmapIds.forEach((item, index) => {
      gsap.to(`#${item}`, {
        scrollTrigger: {
          trigger: `#${item}`,
          once: false
        },
        duration: 0.5,
        x: 0
      });
      index < 5 && gsap.to(`#img-${item}`, {
        scrollTrigger: {
          trigger: `#img-${item}`
        },
        duration: 0.5,
        x: imgMargins[index]
      });
    });
  }, []);

  const swiper = new Swiper('.swiper', {
    // Optional parameters
    // direction: 'horizontal',
    speed: 4000,
    allowTouchMove: false,
    preventClicks: true,
    // direction: 'horizontal',
    // freeMode: {
    //   enabled: true,
    //   momentum: true
    // },
    // effect: 'coverflow',
    // coverflowEffect: {
    //   rotate: 30,
    //   slideShadows: false,
    // },
    slidesPerView: 3,
    // spaceBetween: 30,
    autoplay: {
      delay: 0,
      disableOnInteraction: false,
    },
    loop: true,
  });

  useEffect(() => {
    getData();
  }, [blockchain.account]);



  ////////////////////////////////

  const partStory = `Leonardo di ser Piero Da Vinci, born on 15 April 1452, in a small town called Vinci, was one of the most important representatives of the High Renaissance. He was a universalist spirit: painter, sculptor, architect, musician, engineer, inventor, anatomist, geologist, cartographer, botanist, and writer. Leonardo da Vinci is often considered the greatest genius in the entire history of mankind. As great as he is, Da Vinci did not have any wife or children. He died on 2 May 1519, in this time he is leaving the world with his extremely revolutionary inventions and knowledge to humanity.
                     <br/><br/>
                     Chapter 1 - Family, ...`;
  const fullStoryText = `Leonardo di ser Piero Da Vinci, born on 15 April 1452, in a small town called Vinci, was one of the most important representatives of the High Renaissance. He was a universalist spirit: painter, sculptor, architect, musician, engineer, inventor, anatomist, geologist, cartographer, botanist, and writer. Leonardo da Vinci is often considered the greatest genius in the entire history of mankind. As great as he is, Da Vinci did not have any wife or children. He died on 2 May 1519, in this time he is leaving the world with his extremely revolutionary inventions and knowledge to humanity.
<br/><br/>
<span class="block text-center mbott15px"><b>Chapter 1 - Family</b></span>
But that is not all, you see, there are certain aspects of his personal life that have never been revealed. Since 1478, we see several paintings with Madonna and a child. Many consider it to be the interpretation of the Virgin Mary and Jesus as a child… or maybe that's what Leonardo DaVinci wanted us to believe.
<br/><br/>
In 1490, a 10-12 year old boy named Gian Giacomo Caprotti da Oreno, known as Salai, started living with Leonardo DaVinci to learn from him. His nickname, Salai, translates as “little devil”, a name he proved to be worthy of countless times. Leonardo described him in one of his notebooks as "a thief, a liar, stubborn and a glutton". He stole money from Leonardo at least 5 times. But he forgave him every time and Salai stayed with him for another 30 years.
<br/><br/>
Why would Leonardo DaVinci, a great artist recognized in both Italy and France, hold someone so close to him? Moreover, Leonardo used Salai’s face as an inspiration when he painted Bacchus 1510-1515 and Saint John the Baptist 1513-1516. Imagine… his last paintings were with the face of his disciple Salai, which caused him problems throughout his life, but DaVinci kept him close because he loved him.
<br/><br/>
When you ask these questions, the answer is very simple. Salai is Leonardo's child, his only legal heir, and the bearer of his genius blood. Therefore, all the paintings with Madonna and child are nothing but an interpretation of his vision with his family. Leonardo DaVinci was very good at creating secret codes, so he made the greatest of his life, hiding his descendants. To be convincing, he left some of his paintings to his good friend, Francis I, the King of France, before he died. They remained in possession of the royal family until 1789, when the French Revolution started, and later became part of the Louvre Museum. Today, however, it is known that part of his fortune was left to his disciples Salai and Count Francesco Melzi, the son of an aristocrat from Lombardy. He was considered Leonardo's favorite, following him to France and remaining with him until his death. Melzi was also the executor of his will.
<br/><br/>
What he left exactly to his disciples is not known, at least until now. Salai would inherit his greatest creation, the Mona Lisa Version 2 and a roadmap used to hide the secret from the world. Generation after generation they adapt so the people never find out where the painting is, along with other secrets and treasures. But today this secret comes to light in a form of an NFT and this is your unique chance to discover it.
<br/><br/>
It is known that Mona Lisa is the face of the noblewoman Lisa del Giocondo, the wife of Francesco del Giocondo. The title of the painting came from her name, Lisa and Mona, the acronym for the word Madonna. This version of the Mona Lisa V2, his original creation, is in fact the face of Salai's mother, Leonardo DaVinci's hidden wife.
<br/><br/>
Many want to get their hands on this treasure that was left behind by Leonardo and Salai, but for that, a final code created by the two must be broken. The competition is great, but the rewards are worthy. Join the project and you will have access to the secret code, and the chance to find this priceless treasure and much more.
<br/><br/>
It will not be easy at all, as Leonardo's genius has proven itself countless times. For example, he studied the human anatomy and realized how the heart works, creating a glass design to replicate the blood flow of the heart. Leonardo's prediction of systolic blood flow only being proved correct in 2014, 500 years later. He also created a map of the city of Imola for the politician Cesare Borgia, a map that can be used today. Its mathematical accuracy is incredibly close to the accuracy of a satellite, using equipment designed and made by himself 500 years ago. These are just a few examples of his genius that got him the reputation he has today.
<br/><br/>
Therefore, the code created by him will be in line with his genius and not everyone can succeed, but if you accept the challenge, this will be the first step to prove that you are worthy of his treasure.`
  const [menuActive, setMenuActive] = useState(false);
  const [timeExpired, setTimeExpired] = useState(false);
  const [storyPart, setStoryPart] = useState(true);
  const [fullstory, setFullstory] = useState(partStory);
  const [fullStoryHeight, setFullStoryHeight] = useState(undefined);
  const roadmap = [
    {
      title: 'Vinci',
      text: `
        The genius awakens, you are ready to join our Discord Channel and social media platforms and stay tuned for our whitelist participation process.  
        <ul>
          <li>Whitelist Mint Date: TBA</li>
          <li>Public Mint Date: TBA</li>
        </ul>
      `,
      images: [portavoce]
    },
    {
      title: 'First Florentine period',
      text: `Short time after reveal holders will have access to the secret code, there will be no time limitation for this one and who ever solve the mystery will win:
        <ul>
          <li>The prize of 50.000$ (in ETH)</li>
          <li>1/1 Mona Lisa NFT inside the collection.</li>
        </ul>
      `,
      images: [prize]
    },
    {
      title: 'First Milanese period',
      text: `After 10 days of holding the NFT you will generate DaVinci Code Points each day per NFT that will have later use in the Merchandise Store, Escape Room Game and others.
You will also be able to generate Certificate of Authenticity and have the IP rights in case of creating a unique business by displaying the art.
`,
      images: [monede, certificat]
    },
    {
      title: 'Second Florentine period',
      text: `We are taking art to another level. There will be available custom commissions for your NFT by creating real paintings or 3D figurines from different materials like plastic, bronze, epoxy resin or even gold, thanks to our talented artists.
      <br/>
For those who will acquire a painting or a 3D figurine they will receive the certificate in physical format too.
`,
      images: []
    },
    {
      title: 'Second Milanese period',
      text: `Once the main secret code will be solved, we will generate new ones exclusively for the holders, there will be prizes in cash and the prize pool will be 20% of the royalty fees generated each 14 days or 30 days. This will give the opportunity for everyone to get in the hall of fame where winning wallets will be display for full transparency.`,
      images: [mystery]
    },
    {
      title: 'Rome',
      text: `Merch store is being developed with unique art designs. Exclusive discounts and limited-edition versions for the holders.
      <br/>
Escape Room Game is being developed, as it’s going to challenge your knowledge in order to win prizes in money. Da Vinci Code Points will be used for free access and other exclusive perks only for holders.
`
    },
    {
      title: 'France',
      text: `There will be no end in this project as we are going to expand more, making partnership with other projects, and creating more of our own.`,
    }
  ];
  const faq = [
    {
      q: 'What is The DaVinci Code NFT?',
      a: `   Created by Andrei Sandof and its team, this project represents the combination of art and knowledge, meant for the people that seeks the mystery and the story of a genius.
      <br/>
        There will be 9,999 DaVinci portrait NFT, 3D generated with unique traits and 1/1 Mona Lisa portrait inside the collection.`
    },
    {
      q: 'How do I get on the Whitelist?',
      a: `In order to get on the whitelist, you will have to keep an eye on our Twitter account and join our Discord server for more information.`
    },
    {
      q: 'Will there be a Public Sale? How does it works?',
      a: `Yes, the remaining NFT’s will be available for the public. Splitting the sale in 2 days will help reduce the gas fee, also it’s important to mention that in both days it will be a fair distribution.`
    },
    {
      q: 'What is Da Vinci Code Points and how can I use them?',
      a: `Da Vinci Code Points or DVC Points for short, will be a coin inside our ecosystem that is going to be generated each day per NFT for holding it. More NFT’s you have, more points you will generate. You can use them to get free march or get a 3D figurine or painting made by our artists, even reset the wait time for answering riddles etc.`
    },
    {
      q: 'What’s the nature of the code? What is this mystery all about?',
      a: `The code will be a riddle, you will have access to it short time after reveal. We will add a page to our website, exclusive for our holders where you can see the code, DVC points, hall of fame and others.
      <br/>
   Mystery will be about Leonardo Da Vinci Life’s and people in it and every answer to the riddles will be based on true stories. (At the request of the community we can add blockchain or general knowledge riddles)`
    },
    {
      q: 'What happens after the main secret code will be solve?',
      a: `We will never stop our quest of learning and evolving, so we will create new codes to be solved by the holders. There will be a variation in time and generated codes, so we will generate between 3 to 7 codes in 14 or 30 days time period. For exemple: after 14 days the main code was solved, we will generate 3 codes, holders will have for exemple 30 days to solve this code and win money and then another 6 codes will be generated etc.
      <br/>
   Holders that solve the codes will have their wallets displayed on the hall of fame for full transparency.`
    },
    {
      q: 'What’s the prize pool for the new generated codes?',
      a: `The prize pool will be represented by 20% of the royalty fees giving a period amount of time (14 or 30 days) and split between the number of codes (3 to 7 codes). The volume of ETH inside the collection can be check on OpenSea, but we will also show you the numbers evey time on our Discord and Twitter, again for full transparency.`
    },
    {
      q: `What is the hall of fame?`,
      a: `As we said there will be this major riddle followed by others so we could give holders a permanent chance of winning prizes by resolving a mystery. The winner’s wallets will be displayed here, in the hall of fame.`,
    },
    {
      q: `Between the story presented and history what is true and what is not?`,
      a: `   If I made you wonder, that’s good because now you must find out for yourself, one thing is for sure all the riddles will be inspired by true history and so the answers. `,
    },
    {
      q: `More questions?`,
      a: `I guess it’s time to join the community, you may find the answers there, also you can ask others if you have more questions!`,
    }
  ]
  const team = [
    {
      name: 'Andrei Sandof',
      info: 'Entrepreneur, engineer and NFT project manager.The only creator of the story and secret code.',
      img: andrei
    },
    {
      name: 'Vali Lancea',
      info: 'Digital artist, NFT creator. If I can imagine it, I can create it. Over 15 years of experience with my sharp digital pen.',
      img: vali
    },
    {
      name: 'Daniel Ionescu',
      info: 'Visual artist and UX/UI designer with over 20 years of experience.',
      img: deni
    },
    {
      name: 'Cristian Gherghel',
      info: 'Senior web dev, specialized in working with a wide range of web technologies.',
      img: cristian
    },
    {
      name: 'Andrei Doman',
      info: 'Backend and smart contracts developer',
      img: doman
    }
  ];
  const menu = ['home', 'story', 'roadmap', 'faq', 'team'];

  useEffect(() => {
    const wHeight = window.innerHeight;
    if (wHeight >= 800) {
      const elm = document.querySelector('.banner-desktop');
      elm.style.height = `${+wHeight - 66}px`;
    }
  }, []);

  function toggleMobileMenu () {
    setMenuActive(!menuActive);
  }

  function handleScrollTo (ev, id) {
    ev.preventDefault();
    setMenuActive(false);
    const elm = document.getElementById(id);
    const offset = 80;
    const elmPosition = elm.getBoundingClientRect().top;
    const offsetPosition = elmPosition + window.scrollY - offset;
    window.scrollTo({
      top: offsetPosition,
      behavior: "smooth"
    });
  }

  function handleConnectWallet (ev) {
    ev.preventDefault();
    dispatch(connect());
    getData();
  }

  function handleBuy (ev) {
    ev.preventDefault();
    claimNFTs();
    getData();
  }

  function handleDecrement (ev) {
    ev.preventDefault();
    decrementMintAmount();
  }

  function handleIncrement (ev) {
    ev.preventDefault();
    incrementMintAmount();
  }

  function formatAddress () {
    return `${blockchain.account.substring(0, 2)}...${blockchain.account.substring(38, 42)}`
  }

  function revealStory () {
    const elm = document.getElementById('full-story');
    const initialHeight = elm.scrollHeight;
    setFullStoryHeight(initialHeight);
    if (!storyPart) {
      elm.style.height = `${fullStoryHeight}px`;
      setTimeout(() => {
        setFullstory(partStory);
        setStoryPart(true);
      }, 500);
      return
    }
    elm.style.height = `${initialHeight}px`;
    setFullstory(fullStoryText);
    setTimeout(() => {
      const height = elm.scrollHeight;
      elm.style.height = `${height}px`;
    }, 0)
    setStoryPart(false);
  }

  const HeroText = () => {
    return <p className='mtop25px'>
      <i className='light-yellow davinci'>“Learning is the only thing the mind never exhausts, never fears, and never regrets.</i>
      <span className='quotes light-yellow davinci'>”</span>
      <br/>
      <b className='signature davinci block text-right quotes light-yellow'>Leonardo Da Vinci</b>
      <br/>
      The main quest of this project is to challenge your mind to become a better version of yourself. Art, mystery, knowledge, and wisdom are the heart of this project. Therefore, Andrei Sandof presents to you the story behind the scenes, so you can solve the Da Vinci code and win a prize worth 50.000$ and the lost painting of Mona Lisa as an NFT.
      <br/><br/>
      Did you know that the portrait of a man in Red Chalk is the only self portrait of Leonardo Da Vinci? As his face had faded through the years, we wanted to recreate his portrait in the modern world, by making 9.999 NFT’s 3D generated with unique traits.
      <br/><br/>
      <figure className='sm-cta flex align-center'>
        <a className='flex-center' href="#">
          JOIN <img src={discord} alt="discord"/>
        </a>
        <a className='flex-center' href="#">
          JOIN <img src={twitter} alt="twitter"/>
        </a>
      </figure>
    </p>
  }

  return (
    <main className="app-page-copy"
          id="home">

      <header className="flex align-center">
        <img className='logo' src={logo} alt=""/>
        {!timeExpired && <button>
            <a href="https://whitelist.davincicodenft.io"
               target='_blank'>
              Get whitelisted
            </a>
          </button>}
        {timeExpired &&
          <button id="connect-wallet"
                  onClick={handleConnectWallet}>
            Connect wallet
          </button>}
        <span onClick={toggleMobileMenu}
              className='menu-icon'>
          <svg width="23" height="16" viewBox="0 0 23 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M0.515625 13.6074L22.4277 13.6074L22.4277 15.791L0.515625 15.791L0.515625 13.6074Z" fill="white"/>
            <path d="M0.515625 7.05664L22.4277 7.05664L22.4277 9.24023L0.515625 9.24023L0.515625 7.05664Z" fill="white"/>
            <path d="M0.515624 0.505859L22.4277 0.505858L22.4277 2.68945L0.515624 2.68945L0.515624 0.505859Z" fill="white"/>
          </svg>
        </span>
        <nav className={`flex ${menuActive ? 'active' : ''}`}>
          <ul>
            {menu.map((item) => (
              <li key={item}
                  onClick={ev => handleScrollTo(ev, item)}>
                <span className='capitalize'>
                  {item}
                </span>
              </li>
            ))
            }
            <li className='flex-center'>
              <figure className='flex flex-between'>
                <a href="#">
                  <img className='sm-icons' src={discord} alt="discord"/>
                </a>
                <a href="#">
                  <img className='sm-icons' src={twitter} alt="twitter"/>
                </a>
              </figure>
            </li>
          </ul>
          <i onClick={toggleMobileMenu}>
            <svg className='block m-auto' width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M20 0C8.85714 0 0 8.85714 0 20C0 31.1429 8.85714 40 20 40C31.1429 40 40 31.1429 40 20C40 8.85714 31.1429 0 20 0ZM20 37.1429C10.5714 37.1429 2.85714 29.4286 2.85714 20C2.85714 10.5714 10.5714 2.85714 20 2.85714C29.4286 2.85714 37.1429 10.5714 37.1429 20C37.1429 29.4286 29.4286 37.1429 20 37.1429Z" fill="white"/>
              <path d="M27.7143 30L20 22.2857L12.2857 30L10 27.7143L17.7143 20L10 12.2857L12.2857 10L20 17.7143L27.7143 10L30 12.2857L22.2857 20L30 27.7143L27.7143 30Z" fill="white"/>
            </svg>
          </i>
        </nav>
      </header>

      <section className="banner text-center">
        <h1>The <br/> Da Vinci Code NFT</h1>
        <h4>the story behind the scenes</h4>
        <div className="hide-on-desktop flex-center">
          <Gallery />
          <br/>
        </div>
        <HeroText />
      </section>

      <section className='banner-desktop relative'>
        <h1>The <br/> Da Vinci Code NFT</h1>
        <h4>the story behind the scenes</h4>
        <div className='stripe relative'>
          <img src={stripe} alt=""
               className="w100 absolute left0 top0 z1"/>
          <article className='flex flex-between relative'>
            <HeroText />
            <div className="gallery swiper">
              <div className="swiper-wrapper">
                <div className="swiper-slide">
                  <img src={NFT} alt="davinci code nft"/>
                </div>
                <div className="swiper-slide">
                  <img src={NFT} alt="davinci code nft"/>
                </div>
                <div className="swiper-slide">
                  <img src={NFT} alt="davinci code nft"/>
                </div>
              </div>
            </div>
          </article>
        </div>
        <figure className='absolute left0 top0 wh100 z0'>
          <img className='absolute left0 top0'
               src={oldman}
               alt="" />
          <img className='absolute right0 top0 m-left-auto'
               src={monalisa}
               alt="" />
        </figure>
      </section>

      <figure className='inter-block-icon'>
        <img src={star} className='block m-auto' alt="star icon"/>
      </figure>

      <section id='story'
               className="full-story">
        <h2 className='text-center'>
          DaVinci Secret Story
        </h2>
        <article>
          <p id='full-story' dangerouslySetInnerHTML={{__html: fullstory}} />
        </article>

        {/*<button className={`read-more block ${storyPart ? '' : 'hide-read-more'}`}>*/}
        <button className={`read-more block ${storyPart ? '' : 'hide-read-more'}`}>
          <img src={read} alt="read more"/>
          <span className='block relative z9'
                onClick={revealStory}>
             {storyPart ? 'read full story' : 'short version'}
          </span>
        </button>
      </section>

      <figure className='text-center hide-on-mobile'>
        <img className='multiply' src={element_1} alt="DaVinci code nft"/>
      </figure>

      <section id='roadmap'
               className="roadmap">
        <h2>Roadmap</h2>
        <ul>
          {roadmap.map(({title, text, images}, index) => (
            <li className='flex-center'
                key={title.replace(/ /g, '-')}>
              {
                index % 2 === 0 && images?.length &&
                  <div id={`img-${title.replace(/ /g, '-')}`}
                       className='left flex'>
                    {
                      images.map((img, idex) => <img key={idex} src={img} alt={title} />)
                    }
                  </div>
              }
              <div className={`m-left-auto ${index % 2 === 0 ? 'right' : 'left'}`}
                   id={title.replace(/ /g, '-')}>
                <h3 className='first-letter'>{title}</h3>
                <p dangerouslySetInnerHTML={{__html: text}} />
              </div>
              {
                index % 2 === 1 && images?.length &&
                  <div id={`img-${title.replace(/ /g, '-')}`}
                       className='right flex'>
                    {
                      images.map((img, idex) => <img key={idex} src={img} alt={title} />)
                    }
                  </div>
              }
            </li>
          ))}
        </ul>
        <p className='text-center'>
          Join us now and be part of history, if you think you have what it takes to solve Leonardo Da Vinci Code!
        </p>
      </section>

      <figure className='inter-block-icon'>
        <img src={faqIcon} className='block m-auto' alt="star icon"/>
      </figure>

      <section id='faq'
               className="faq">
        <h2 className='text-center'>FAQ</h2>
        <ul>
          {faq.map(({q, a}, index) => (
            <li key={index}>
              <h3 className='flex'>
                <span className='inline-block mright5px'>{index + 1}.</span>
                <span className='inline-block'>{q}</span>
              </h3>
              <p dangerouslySetInnerHTML={{__html: a}} />
            </li>
          ))}
        </ul>
      </section>

      <figure className='inter-block-icon'>
        <img src={brainIcon}
             className='brain-icon block m-auto'
             alt="star icon"/>
      </figure>

      <section id='team'
               className="team">
        <h2 className='text-center'>Genius Team Members</h2>
        <ul>
          {
            team.map(({name, info, img}) => (
              <li key={name} className='text-center'>
                <img src={img} alt=""/>
                <h4 className='mbott8px'>{name}</h4>
                <p className='text-center mt0'>{info}</p>
              </li>
            ))
          }
        </ul>
      </section>

      <figure className='inter-block-icon'>
        <img src={boat}
             className='boat-icon block m-auto'
             alt="star icon"/>
      </figure>

      <section className="social-media">
        <p>
          Join the renaissance age, some will say that is a leap back in time, but let’s be serious it was the moment when legends were born.
          <br/>
          <br/>
          <span>Be a legend!</span>
        </p>
        <figure className='flex flex-between'>
          <a href="#">
            <img className='sm-icons' src={discord} alt="discord"/>
          </a>
          <a href="#">
            <img className='sm-icons' src={twitter} alt="twitter"/>
          </a>
        </figure>
      </section>

      <footer className='flex'>
        <a href="#">
          <img className='logo' src={logo} alt=""/>
        </a>
        <nav className='w100'>
          {menu.map(item => (
            <a className='capitalize'
               onClick={ev => handleScrollTo(ev, item)}
               key={item} href={`#${item}`}>{item}</a>
          ))}
        </nav>
        <figure className='flex flex-between align-center'>
          <a href="#">
            <img className='sm-icons' src={discord} alt="discord"/>
          </a>
          <a href="#">
            <img className='sm-icons' src={twitter} alt="twitter"/>
          </a>
        </figure>
      </footer>

    </main>
  );
}

export default App;
